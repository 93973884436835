import { useIntl } from "react-intl"

import { Button, Chip, StaffBodyTextM, StaffHeading3 } from "@doktor-se/bones-ui"
import { AssignedConversation, PaymentRequired } from "@doktor-se/bones-ui/dist/web-shared/types"

import { SearchPageConversation } from "types"

import styles from "./PaymentContent.module.scss"

interface PaymentContentProps {
  conversation: AssignedConversation | SearchPageConversation
  paymentTag?: PaymentRequired
  setPaymentTag: (paymentTag: PaymentRequired) => void
  onConfirm: () => void
  confirmButtonTranslationId: string
}

const PaymentContent = ({
  conversation,
  paymentTag,
  setPaymentTag,
  onConfirm,
  confirmButtonTranslationId
}: PaymentContentProps) => {
  const intl = useIntl()

  const showCardExemptOption = conversation.metadata?.county !== "01" // Stockholm

  return (
    <>
      <div className={styles.container}>
        <StaffHeading3>
          {intl.formatMessage({
            id: "control.close"
          })}
        </StaffHeading3>

        {showCardExemptOption ? (
          <>
            <StaffBodyTextM margin={{ bottom: "var(--size-200)" }}>
              {intl.formatMessage({ id: "control.tag.close" })}
            </StaffBodyTextM>

            <Chip
              isSelected={paymentTag === "card_exempt"}
              color="accent"
              onChange={() => setPaymentTag("card_exempt")}>
              {intl.formatMessage({ id: "label.yes" })}
            </Chip>
            <Chip
              isSelected={paymentTag === "payment_accepted"}
              color="accent"
              onChange={() => setPaymentTag("payment_accepted")}>
              {intl.formatMessage({ id: "label.no" })}
            </Chip>
          </>
        ) : (
          <>
            <StaffBodyTextM margin={{ bottom: "var(--size-200)" }}>
              {intl.formatMessage({ id: "control.tag.close.accept.payment" })}
            </StaffBodyTextM>

            <Chip
              isSelected={paymentTag === "payment_accepted"}
              color="accent"
              onChange={() => setPaymentTag("payment_accepted")}>
              {intl.formatMessage({ id: "label.yes" })}
            </Chip>
          </>
        )}

        <Chip isSelected={paymentTag === "not_relevant"} color="accent" onChange={() => setPaymentTag("not_relevant")}>
          {intl.formatMessage({ id: "label.na" })}
        </Chip>
      </div>

      <Button variant="primary" color="primary" onPress={onConfirm} isDisabled={!paymentTag} fullWidth>
        {intl.formatMessage({ id: confirmButtonTranslationId })}
      </Button>
    </>
  )
}

export default PaymentContent
