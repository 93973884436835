import { useCallback, useEffect, useMemo } from "react"
import { useIntl } from "react-intl"

import { StaffBodyTextM } from "@doktor-se/bones-ui"
import { Feed, Loader, Texts } from "@doktor-se/bones-ui/dist/web-shared/components"
import { AnyFilteredMessage, AssignedConversation, ProfileMessage } from "@doktor-se/bones-ui/dist/web-shared/types"

import {
  answerCall,
  checkPermissions,
  createLoadingSelector,
  deleteMessage,
  endCall,
  fetchSignedUrl,
  lastMessageRead,
  patientProfilesFetch,
  sendActionButton,
  sendComment,
  sendMessage,
  uploadFile,
  webdocUpload
} from "api"
import { iAmTyping } from "api/ws/handlers/handleTyping"
import { appStatic, featureFlags, paymentMethod, phrasesLanguages, webdocFeatureEnabled } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { staffIsOnConsentGivenList } from "lib/seald"
import { enterSend } from "reducers/app"
import { selectBookingById } from "reducers/booking"
import { categorySelectors } from "reducers/categories"
import { selectLocalConversationState, updateLocalConversationState } from "reducers/selected"
import { patientProfilesSelectors } from "reducers/users/users.reducer"

import PayconiqDialog from "components/payments/payconiq/PayconiqDialog"
import PatientDetailsPanel from "pages/dashboard/components/PatientDetailsPanel/PatientDetailsPanel"

import styles from "./ConversationContainer.module.scss"

interface ConversationContainerProps {
  conversation: AssignedConversation
  hasQueuePanel: boolean
  messagesToDisplayFiltered: AnyFilteredMessage[]
  conversationIdForFilteredMessages: string
  texts: Texts
  sessionState?: "pending" | "success" | "error"
}

const webdocLoadingSelector = createLoadingSelector(["WEBDOC"])
const fileUploadLoadingSelector = createLoadingSelector(["FILE UPLOAD"])

export const EncryptedConversationContainer = (props: ConversationContainerProps): JSX.Element | null => {
  const intl = useIntl()

  if (props.sessionState === "pending") {
    return <Loader type="small" />
  }

  if (props.sessionState === "error") {
    return (
      <div className={styles.encryptionSessionErrorBox}>
        <StaffBodyTextM>{intl.formatMessage({ id: "conversation.encryption.error.session" })}</StaffBodyTextM>
      </div>
    )
  }

  return <ConversationContainer {...props} />
}

export const ConversationContainer = ({
  conversation,
  hasQueuePanel,
  messagesToDisplayFiltered,
  conversationIdForFilteredMessages,
  texts
}: ConversationContainerProps): JSX.Element | null => {
  const dispatch = useAppDispatch()

  const localConversationState = useAppSelector(selectLocalConversationState)
  const staff = useAppSelector(state => state.users.staff)
  const enterState = useAppSelector(state => state.app.enterState)
  const { phrases } = useAppSelector(state => state.phrases)
  const loaderForWebdocUpload = useAppSelector(state => webdocLoadingSelector(state))

  const loaderForFileUpload = useAppSelector(state => fileUploadLoadingSelector(state))
  const categories = useAppSelector(categorySelectors.selectEntities)
  const user = useAppSelector(state => state.auth.user)
  const patientProfiles = useAppSelector(
    state => conversation && patientProfilesSelectors.selectById(state, conversation.patientId)
  )?.profiles
  const defaultRole = useAppSelector(state => state.auth.defaultRole)
  const sealdEncryptionFeatureEnabled = useAppSelector(state => state.encryption.encryptionEnabled)

  useEffect(() => {
    if (!conversation || !!patientProfiles || !conversation.messages) return
    const profileMessages = conversation.messages.filter(message => message.type === "profile") as ProfileMessage[]
    const hasOldProfileMessage = profileMessages.find(message => message.data.newProfileId !== conversation.profile?.id)
    if (hasOldProfileMessage) dispatch(patientProfilesFetch(conversation?.patientId))
  }, [conversation, dispatch, patientProfiles])

  const fetchSignedUrlCallback = useCallback(
    (key: string, conversationId: string) => {
      return dispatch(fetchSignedUrl(key, conversationId))
    },
    [dispatch]
  )

  const booking = useAppSelector(selectBookingById(conversation?.metadata?.bookingId))

  const isUrgentCareConversation = useMemo(() => !!booking?.clinicId, [booking])

  const getPaymentDialog = () => {
    switch (paymentMethod) {
      case "payconiq":
        return PayconiqDialog
      default:
        return undefined
    }
  }

  const awaitingConsent = useMemo(() => {
    return (
      user &&
      sealdEncryptionFeatureEnabled &&
      (!conversation.customerMetadata?.encryption || !staffIsOnConsentGivenList(user, conversation))
    )
  }, [conversation, sealdEncryptionFeatureEnabled, user])

  const encryptionEnabled = useMemo(() => {
    return sealdEncryptionFeatureEnabled && !awaitingConsent && !!conversation.customerMetadata?.encryption
  }, [awaitingConsent, conversation.customerMetadata?.encryption, sealdEncryptionFeatureEnabled])

  if (!user) return null

  return (
    <>
      <Feed
        className={styles.feedContainer}
        user={user}
        staff={staff}
        conversation={conversation}
        messagesToDisplaySearched={messagesToDisplayFiltered}
        conversationIdForFilteredMessages={conversationIdForFilteredMessages}
        categories={categories}
        localConversationState={localConversationState}
        phraseData={phrases}
        updateLocalConversationState={state =>
          dispatch(updateLocalConversationState({ conversationId: conversation.id, state }))
        }
        conversationSeen={(conversationId, messageId) => dispatch(lastMessageRead(conversationId, messageId))}
        sendMessage={(conversationId, draft, staffName, patientId) =>
          dispatch(
            sendMessage(
              conversationId,
              draft,
              staffName,
              patientId,
              sealdEncryptionFeatureEnabled,
              conversation?.customerMetadata?.encryption
            )
          )
        }
        sendActionButton={(conversationId: string, title: string, uri: string, event?: string) =>
          dispatch(sendActionButton(conversationId, title, uri, event))
        }
        sendComment={(conversationId, draft) => dispatch(sendComment(conversationId, draft))}
        uploadFile={(file, conversationId, staffName, patientId) =>
          dispatch(
            uploadFile(
              file,
              conversationId,
              staffName,
              patientId,
              sealdEncryptionFeatureEnabled,
              conversation?.customerMetadata?.encryption
            )
          )
        }
        viewOnly={isUrgentCareConversation}
        enterSend={() => dispatch(enterSend())}
        enterState={enterState}
        endCall={() => dispatch(endCall(conversation, "declined"))}
        answerCall={() => dispatch(checkPermissions(() => dispatch(answerCall(conversation, "answered"))))}
        uploadImage={(url, profile) => dispatch(webdocUpload(url, profile))}
        deleteMessage={messageId => dispatch(deleteMessage(messageId, conversation))}
        iAmTyping={() => iAmTyping(conversation.id)}
        loaderForWebdocUpload={loaderForWebdocUpload}
        loaderForFileUpload={loaderForFileUpload}
        features={featureFlags}
        webdocFeatureEnabled={webdocFeatureEnabled(defaultRole)}
        texts={texts}
        phrasesLanguages={phrasesLanguages}
        phrasesInitialLanguage={
          featureFlags.has("phrases_conversation_language") ? conversation?.metadata?.language : undefined
        }
        fetchSignedUrl={fetchSignedUrlCallback}
        PaymentDialog={featureFlags.has("payments") ? getPaymentDialog() : undefined}
        patientProfiles={patientProfiles}
        appStatic={appStatic}
        showAwaitingEncryptionWarning={awaitingConsent}
        encryptionEnabled={encryptionEnabled}
      />

      <PatientDetailsPanel conversation={conversation} localConversationState={localConversationState} />
      {hasQueuePanel && <div className={styles.column} />}
    </>
  )
}
